import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Textarea } from '../components/ui/textarea';

const determineRarity = (rate) => {
  if (rate >= 0.01) return 'Common';
  else if (rate >= 0.005) return 'Uncommon';
  else if (rate >= 0.0015) return 'Rare';
  else return 'Jackpot';
};

// Define reward sets with item rates, costs, and batch sizes
const rewardSets = {
  neonShinobiBox: {
    rates: `0.12% 	Special Neon Blossom Shinobi Wear (M)
0.12% 	Special Neon Blossom Shinobi Wear (F)
0.20% 	Neon Blossom Shinobi Wear (M)
0.20% 	Neon Blossom Shinobi Wear (F)
0.22% 	Neon Blossom Shinobi Gloves (M)
0.22% 	Neon Blossom Shinobi Gloves (F)
0.22% 	Neon Blossom Shinobi Shoes (M)
0.22% 	Neon Blossom Shinobi Heels (F)
0.22% 	Neon Blossom Shinobi Wig (M)
0.22% 	Neon Blossom Shinobi Wig (F)
0.19% 	Blazing Assault Slash Effect Change Card (Blossom) Coupon
0.19% 	Kunai Storm Effect Change Card (Blossom) Coupon
0.19% 	Fireball Effect Change Card (Nebular Maelstrom) Coupon
0.19% 	Fireball Effect Change Card (Cosmic Maelstrom) Coupon
0.19% 	Fireball Effect Change Card (Deep Space Maelstrom) Coupon
0.19% 	Bullet Storm Effect Change Card (Explosive Salvo) Coupon
0.19% 	Shooting Rush Effect Change Card (Explosive Salvo) Coupon
0.19% 	Dual Gun Mastery Effect Change Card (Explosive Salvo) Coupon
0.19% 	Bullet Slide Effect Change Card (Explosive Salvo) Coupon
0.19% 	Spirit Transformation Liqueur (Blossoming Butterflies)
0.19% 	Spirit Transformation Liqueur (Smoke Cloud)
0.20% 	Neon Blossom Shuriken Appearance Scroll
0.20% 	Neon Blossom Katana Appearance Scroll
0.20% 	Neon Blossom Shinobi Mask (Face Accessory Slot Exclusive)
0.22% 	Blossoming Plum Tree Chair
0.08% 	Meteoroid Enchant Scroll Coupon
0.08% 	Clashing Enchant Scroll Coupon
0.19% 	Spiraling Enchant Scroll Coupon
0.19% 	Supernova Enchant Scroll Coupon
0.11% 	Eternal Resistance Tactical Crossbow Appearance Scroll
0.11% 	Revolutionary Resistance Tactical Crossbow Appearance Scroll
0.11% 	Eternal Resistance Bow Appearance Scroll
0.11% 	Eternal Resistance Two-Handed Sword Appearance Scroll
0.17% 	Eternal Resistance Knuckles Appearance Scroll
0.19% 	Eternal Resistance Dual Guns Appearance Scroll
0.19% 	Eternal Resistance Replica Sniper Rifle Two-Handed Blunt Weapon Appearance Scroll
0.19% 	Eternal Resistance Gatling Dual Gun Appearance Scroll
0.12% 	Special Moonlit Howls Outfit (F)
0.12% 	Special Moonlit Howls Outfit (M)
0.17% 	Special Hyperspace Aurora Suit (F)
0.17% 	Special Hyperspace Aurora Suit (M)
0.17% 	Special Hyperspace Flare Suit (F)
0.17% 	Special Hyperspace Flare Suit (M)
0.17% 	Hyperspace Aurora Shoes (F)
0.17% 	Hyperspace Aurora Shoes (M)
0.31% 	Revolutionary Resistance Replica Sniper Rifle Two-Handed Blunt Weapon Appearance Scroll
0.46% 	Revolutionary Resistance Gatling Dual Gun Appearance Scroll
0.31% 	Mafia Dual Rifles Appearance Scroll
0.46% 	Mafia Dual Revolvers Appearance Scroll
0.62% 	Moonlit Howls Outfit (F)
0.62% 	Moonlit Howls Outfit (M)
0.62% 	Moonlit Howls Heels (F)
0.62% 	Moonlit Howls Shoes (M)
0.62% 	Steampunk Inventress Dress (F)
0.62% 	Steampunk Inventor Suit (M)
0.62% 	Laced Leather Boots (F)
0.62% 	Studded Leather Boots (M)
0.62% 	Hyperspace Aurora Suit (F)
0.62% 	Hyperspace Aurora Suit (M)
0.62% 	Hyperspace Flare Suit (M)
0.62% 	Hyperspace Flare Suit (F)
0.62% 	Hyperspace Aurora Gloves (F)
0.62% 	Hyperspace Aurora Gloves (M)
0.31% 	Homestead Autumn Train Station
0.31% 	Homestead Autumn Train Track
0.31% 	Camping Enthusiasts Set (for 8)
0.46% 	Camping Bicycle Set (for 2)
0.46% 	Camping Sleeping Bags Set (for 8)
0.46% 	Camping Companions Set (for 6)
0.62% 	Camping Buddies Set (for 4)
0.62% 	Sleeping Bag
0.77% 	Camping Campfire Set (for 2)
0.77% 	Camping Tent Set (for 2)
0.77% 	Astronomical Telescope
0.46% 	Charming Floating Cat Cushion
0.77% 	Summer Royal Crown Halo
0.77% 	Autumn Royal Crown Halo
0.77% 	Aquarius Galaxy Halo
0.77% 	Sagittarius Galaxy Halo
0.77% 	Gemini Galaxy Halo
1.08% 	Moonlit Howls Hand Ornament (F)
1.08% 	Moonlit Howls Gloves (M)
1.08% 	Steampunk Monocle
1.08% 	Steampunk Inventress Wig (F)
1.08% 	Steampunk Inventor Wig (M)
1.08% 	Steampunk Inventress Hat (F)
1.08% 	Steampunk Inventor Hat (M)
1.08% 	Steampunk Inventress Wig and Hat (F)
1.08% 	Steampunk Inventor Wig and Hat (M)
1.08% 	Steampunk Antique Bracelet (F)
1.08% 	Steampunk Gloves (M)
1.08% 	Camping Wear (M)
1.08% 	Camping Wear (F)
1.08% 	Camping Hat (M)
1.08% 	Camping Wig and Bandanna (F)
1.08% 	Camping Table Set (for 2)
1.08% 	Sweet Academy Bag
1.08% 	Vintage Walking Cane
1.08% 	Dumbo Octopus Plushie
1.08% 	Beluga Whale Plushie
1.39% 	Seraphic Cantabile Conductor's Baton
1.39% 	Casual Tech Chic Hoverboard
1.39% 	Tech Chic Compound Bow
1.39% 	Tech Chic Handheld Console
1.39% 	Graffiti Spray Paint
1.39% 	Homestead Camping Bicycle Set
1.39% 	Trinity Wear (M) (Reforge Rank:1)
1.39% 	Trinity Wear (F) (Reforge Rank:1)
1.39% 	Trinity Gloves (Reforge Rank:1)
1.39% 	Trinity Shoes (Reforge Rank:1)
1.39% 	Trinity Circlet (Reforge Rank:1)
1.58% 	Speed Walk Potion 40% (30 min)
1.58% 	Light Physical Power Potion (2 Hours)
1.58% 	Light Magic Power Potion (2 hrs.)
1.58% 	Crusader EXP Potion (30 min.) x5
1.58% 	Fixed Color Dye Ampoule (#87CEFA)
1.58% 	Fixed Color Dye Ampoule (#FED0C0)
1.58% 	Fixed Color Dye Ampoule (#43B69B)
1.58% 	Fixed Color Metal Dye Ampoule (#800080)
1.58% 	Fixed Color Metal Dye Ampoule (#F18372)
1.58% 	Fixed Color Hair Dye Ampoule (#DC9C34)
1.58% 	Fixed Color Hair Dye Ampoule (#EFEAB5)
1.58% 	Transparent Hat
1.58% 	Transparent Gloves
1.58% 	Transparent Shoes
1.58% 	Regular Gem Powder x10
1.58% 	Perfect Free Repair Kit
1.58% 	Partner Likeability Increase Candy (30) x5
1.58% 	Essence of Phoenix x10
1.58% 	Sleeve Gesture Card
1.58% 	Sneeze Gesture Card
1.58% 	Slurp Gesture Card
1.58% 	Silly Me Gesture Card`,
    costs: {
      1: 1.5,
      11: 15,
      45: 57.5
    },
    batches: [1, 11, 45]
  }
};

const GachaSimulator = () => {
  const [selectedSet, setSelectedSet] = useState('neonShinobiBox');
  const [prizeInput, setPrizeInput] = useState(rewardSets.neonShinobiBox.rates);
  const [costs, setCosts] = useState(rewardSets.neonShinobiBox.costs);
  const [batches, setBatches] = useState(rewardSets.neonShinobiBox.batches);
  const [rewards, setRewards] = useState([]);
  const [awardedItems, setAwardedItems] = useState({});
  const [totalDraws, setTotalDraws] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    parseRewards();
  }, [prizeInput]);

  useEffect(() => {
    setPrizeInput(rewardSets[selectedSet].rates);
    setCosts(rewardSets[selectedSet].costs);
    setBatches(rewardSets[selectedSet].batches);
  }, [selectedSet]);

  const parseRewards = () => {
    const parsedRewards = prizeInput.split('\n').map(line => {
      const parts = line.split(/\t|(?<=\%)\s+/);
      const rateStr = parts[0].trim();
      const item = parts[1].trim();
      const rate = parseFloat(rateStr.replace('%', '').trim()) / 100;
      return { item, rate };
    });
    setRewards(parsedRewards);
  };

  const drawItems = (drawCount) => {
    const newAwardedItems = { ...awardedItems };
    for (let i = 0; i < drawCount; i++) {
      const random_number = Math.random();
      let cumulativeRate = 0;
      for (const reward of rewards) {
        cumulativeRate += reward.rate;
        if (random_number < cumulativeRate) {
          if (reward.item in newAwardedItems) {
            newAwardedItems[reward.item].count++;
          } else {
            newAwardedItems[reward.item] = {
              count: 1,
              rarity: determineRarity(reward.rate)
            };
          }
          break;
        }
      }
    }
    setAwardedItems(newAwardedItems);
    setTotalDraws(prevTotalDraws => prevTotalDraws + drawCount);

    const batchCost = costs[drawCount] || (1.5 * drawCount); // Default cost if batch not defined
    setTotalCost(prevTotalCost => prevTotalCost + batchCost);
  };

  const filteredItems = Object.entries(awardedItems)
    .filter(([item, data]) => filter === '' || data.rarity === filter)
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <div className="p-4 bg-background text-foreground min-h-screen">
      <Card className="mb-4">
        <CardHeader>Dani's Gacha Sim</CardHeader>
        <CardContent>
          <h2 className="mb-2">
            <a
              href="https://repo.danii.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Repo of my dumb projects
            </a>.
          </h2>
          <h4 className="mb-2">To change the rates/items, copy from the RNG rates table that is linked in every gacha</h4>
          <h4 className="mb-2">Copy only the %s and the names, leave the headers out.</h4>
          <Textarea
            value={prizeInput}
            onChange={(e) => setPrizeInput(e.target.value)}
            className="w-full mb-4"
          />
          <div className="mb-4">
            {batches.map(batchSize => (
              <Button key={batchSize} onClick={() => drawItems(batchSize)} className="mr-2">
                Draw {batchSize}
              </Button>
            ))}
          </div>
          <div>Total Draws: {totalDraws} (${totalCost.toFixed(2)})</div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>Choose Reward Set</CardHeader>
        <CardContent>
          <div className="mb-4">
            <Button onClick={() => setSelectedSet('neonShinobiBox')} className="mr-2">Neon Shinobi Box</Button>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>Results</CardHeader>
        <CardContent>
          <div className="mb-4">
            <Button onClick={() => setFilter('')} className="mr-2">All</Button>
            <Button onClick={() => setFilter('Common')} className="mr-2">Common</Button>
            <Button onClick={() => setFilter('Uncommon')} className="mr-2">Uncommon</Button>
            <Button onClick={() => setFilter('Rare')} className="mr-2">Rare</Button>
            <Button onClick={() => setFilter('Jackpot')}>Jackpot</Button>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Prize</TableHead>
                <TableHead>Total</TableHead>
                <TableHead>Rarity</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredItems.map(([item, data]) => (
                <TableRow key={item}>
                  <TableCell>{item}</TableCell>
                  <TableCell>{data.count}</TableCell>
                  <TableCell>{data.rarity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default GachaSimulator;


